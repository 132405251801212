import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import { log } from "console";
// import { constants } from 'buffer';
import React, { useState, useEffect } from "react";
import DatePicker from "react-date-picker";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { ServerAPI } from "../common/ServerAPI";

interface modalDatas {
  openModal?: any;
  handleClose?: any;
  handleOpen?: any;
  contents?: any;
  currentPage?: any;
  details_datas?: any;
  user_id?: any;

  // handleOpen
}

const ConfirmationModal: React.FC<modalDatas> = ({ handleOpen, openModal, handleClose, contents, currentPage, details_datas, user_id }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { setShowPreloader } = useAppStateAPI();

  useEffect(() => {
    if (openModal === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openModal]);

  const handleCloses = () => {
    handleClose(false);
    setOpen(false);
  };

  const handleSave = () => {
    if (currentPage === "update-status") {
      setShowPreloader(true);
      ServerAPI.UserUpdateStatus(details_datas, user_id)
        .then((res) => {
          toast.success(res.message);
          navigate(PageLinks.USERSDETAILS, { state: user_id });
          handleClose(false);
          setOpen(false);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }

    if (currentPage === "suspended-modal") {
      console.log(details_datas, "userID:" + user_id);
      setShowPreloader(true);
      ServerAPI.AddUpdateStatus(details_datas, user_id)
        .then((res: any) => {
          // navigate(PageLinks.USERS, { state: true })
          toast.success(res.message);
          handleClose(false);
          setOpen(false);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }

    if (currentPage === "suspend-user-list") {
      setShowPreloader(true);
      ServerAPI.ActiveSuspendedUser(details_datas)
        .then((res: any) => {
          handleClose(false);
          setOpen(false);
          navigate(PageLinks.SUSPENDED_USER_LIST, { state: true });
          toast.success(res.message);
          // navigate(PageLinks.USERS, { state: true })
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };

  return (
    <div>
      <Dialog open={open} className="dialog-box" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className="model-title">
          <div className="modal-box-title">
            <p className="m-0 fs-5">Confirmation</p>
            <p className="m-0" onClick={() => setOpen(false)}>
              <i className="fa fa-times cursor-pointer font-style" aria-hidden="true"></i>
            </p>
          </div>
        </DialogTitle>
        <hr />

        <DialogContent>
          <DialogContentText className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <img src="./assets/images/exclaimation.png" alt="exclaimation" className="exclaimation-style" />
              {/* <i className="fa fa-exclamation-triangle text-red " aria-hidden="true"></i> */}
              <p className="m-0 ml-3 text-black-medium fs-6">{contents}</p>
            </div>
            <br />
            <div className="d-flex justify-content-end">
              <a className="cancel-btn_1 mx-3 " onClick={() => handleCloses()}>
                No
              </a>
              <a className="save-btn_1" onClick={() => handleSave()}>
                Yes
              </a>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmationModal;
