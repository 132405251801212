// import { useState } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { PageLinks } from "./common/Constants";
// import Dashboard from "./components/Dashboard";
import Login from "./components/login";
import Cities from "./components/Masters/Cities";
import Users from "./components/Users";
import UsersDetails from "./components/UsersDetails";
import IpvVerification from "./modals/IpvVerification";
import PanVerification from "./modals/PanVerification";
import SuspendedUserList from "./components/SuspendedUsersList";
import AllUsersLogs from "./components/AllUsersLog";
import UserLogs from "./components/IndividualUserLogs"
import Preloader from "./common/Preloader";
import CustomerOrderDetailView from "./components/CustomerOrderDetailView";

const AppNavigate: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path={PageLinks.LOGIN} element={<Login />} />
                <Route path={PageLinks.USERS} element={<Users />} />
                <Route path={PageLinks.CITIES} element={<Cities />} />
                <Route path={PageLinks.USERSDETAILS} element={<UsersDetails />} />
                <Route path={PageLinks.IPVVERIFY} element={<IpvVerification />} />
                <Route path={PageLinks.PANVERIFY} element={<PanVerification />} />
                <Route path={PageLinks.SUSPENDED_USER_LIST} element={<SuspendedUserList />} />
                <Route path={PageLinks.ALL_USERS_LOGS} element={<AllUsersLogs />} />
                <Route path={PageLinks.INDIVIDUAL_USER_LOGS} element={<UserLogs />} />
                <Route path={PageLinks.CUSTOMER_BOOK_DETAIL_VIEW} element={<CustomerOrderDetailView />} />
                <Route path="/" element={<Navigate to={PageLinks.LOGIN} />} />
            </Routes>
            <Preloader />
        </Router>
    )
}

export default AppNavigate;
