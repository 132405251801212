import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Depository, Experience, Gender, GenderString, kraStatus, MarriedStatus, PageLinks, PersonVideVerifyStatus, Status, Yes_or_No, AccountStatus, API, FACTA_Address_Type } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import { getDisplayData, getDownloadFile, getDownloadURLExtension, getFormattedLocalDate, getFormattedLocalDateTime } from "../common/Utilities";
import AccountStatusModal from "../modals/AccountStatusModal";
import PreviewJSONModal from "../modals/PreviewJSONModal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import * as Utilities from "../common/Utilities";
import toast from "react-hot-toast";

const UsersDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customerId = location.state;
  const [userId, setUserId] = useState();
  const [FACTADetails, setFACTADetails] = useState<APIData.FactaDetails[]>([]);
  const [Cvl_kraAddresDetails, setCvl_kraAddressDetails] = useState<APIData.AddressDatas_cvl_kra>();
  const [digilocker_aAddresDetails, setDigilockerAddressDetails] = useState<APIData.Digilocker_address_data>();
  const [existing_demat_details, setExistingDematDetails] = useState<APIData.ExistingDematAccountDetails[]>([]);
  const [new_demat_details, setNewDematDetails] = useState<APIData.NewDematAccountDetails>();
  const [suspended_data, setSuspendedData] = useState({});
  const [account_status_detail, setAccount_status_detail] = useState<APIData.AccountStatusDetails>();
  const [open, setOpen] = useState(false);
  const { setShowPreloader } = useAppStateAPI();
  const [cvlkraJSON, setcvlkraJSON] = useState<any>();
  const [openJSON, setOpenJSON] = useState(false);

  const [personalDetails, setpersonalDetails] = useState<APIData.personalDetails>();
  const [updateAddress, setupdateAddress] = useState<APIData.updateAdddress[]>([]);
  const [bankDetails, setBankDetails] = useState<APIData.bankDetails[]>([]);
  const [aadharDetails, setaadharDetails] = useState<APIData.aadharDetails>();
  const [accountStatus, setaccountStatus] = useState<APIData.accountStatus>();
  const [userDetails, setuserDetails] = useState<APIData.userDetails>();
  const [ipvDetails, setipvDetails] = useState<APIData.ipvDetails>();
  const [panDetails, setpanDetails] = useState<APIData.panDetails>();
  const [documentDetails, setdocumentDetails] = useState<APIData.documentDetails>();
  const [demantDetails, setdemantDetails] = useState<APIData.demantDetails[]>([]);
  const [nominee_details, setNomineeDetails] = useState<APIData.NomineeDetails[]>([]);

  useEffect(() => {
    user_details_datas();
    document_details();
  }, [customerId]);

  const user_details_datas = () => {
    console.log(customerId);
    setShowPreloader(true);
    ServerAPI.UserDetail(customerId)
      .then((res) => {
        setpersonalDetails(res.message.personal_details);
        setupdateAddress(res.message.updated_address);
        setBankDetails(res.message.bank_details);
        setaadharDetails(res.message.aadhar_details);
        setaccountStatus(res.message.account_status);
        setuserDetails(res.message.user_details);
        setipvDetails(res.message.ipv_details);
        setpanDetails(res.message.pan_details);
        setdemantDetails(res.message.demat_details);
        setNomineeDetails(res.message.nominee_details);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const document_details = () => {
    setShowPreloader(true);
    ServerAPI.DocumentDetails(customerId)
      .then((res) => {
        setdocumentDetails(res.message);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleClickOpen = () => {};

  const handleClose = (e: any) => {
    setOpen(e);
    user_details_datas();
  };

  const status_update = (e: any) => {
    setOpen(true);
    setUserId(e);
  };

  const handleCVLKRAjson = (data: any) => {
    console.log(data);
    // const jsonString = JSON.stringify(data);
    // const newWindow = window.open("", "JSON Data");
    // if (newWindow) {
    //     newWindow.document.write(`<html><body><pre>${jsonString}</pre></body></html>`);
    // }
    setOpenJSON(true);
  };

  const handledVerify = (endPoint:any,userID:any) => {
    ServerAPI.getIPVDatas(userID).then(async (res) => {
      console.log(res)
      if(res){
        navigate(endPoint, { state: userID });
      }
    }).finally(() => {
      setShowPreloader(false);
    });
  }

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pt-4">
                    <div className="page-title-rightds">
                      <ol className="breadcrumb ">
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trustmony</a>
                        </li>
                        <li className="breadcrumb-item ">Users</li>
                        <li className="breadcrumb-item active">
                          <b>Detail View</b>
                        </li>
                      </ol>
                    </div>
                    <div className="cursor-pointer" onClick={() => navigate(PageLinks.USERS)}>
                      <p className="mb-0 active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                          <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                        </svg>
                        <span> Back </span>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header col-xl-12 w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="my-3 header"> Users Details </h5>
                            <div className="">
                              {/* <a
                                className="save-btn"
                                onClick={() => {
                                  handleCVLKRAjson(cvlkraJSON);
                                }}
                              >
                                CVL KRA json
                              </a> */}

                              <a className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2" onClick={() => navigate(PageLinks.INDIVIDUAL_USER_LOGS, { state: customerId })}>
                                {" "}
                                User Logs <i className="fas fa-eye table-icons px-2"></i>
                              </a>
                              {accountStatus?.account_status !== AccountStatus.Suspended && (
                                <a className="cancel-btn_1 border-light quicksand-medium px-2 py-2" onClick={(e) => status_update(userDetails?.user_id)}>
                                  {" "}
                                  Account Status: {accountStatus?.account_status === AccountStatus.Active && <span className="text-green quicksand-bold mx-2">Active</span>}
                                  {accountStatus?.account_status === AccountStatus.Closed && <span className="text-red quicksand-bold mx-2">Closed</span>}
                                  {accountStatus?.account_status === AccountStatus.Register && <span className="text-blue quicksand-bold mx-2">Register</span>}
                                  <i className="fa fa-angle-down" aria-hidden="true"></i>{" "}
                                </a>
                              )}
                            </div>
                          </div>

                          {accountStatus?.account_status !== AccountStatus.Register && accountStatus?.account_status !== AccountStatus.Suspended && (
                            <div className="card">
                              <div className="card-header">
                                <h5 className="m-0 header"> Account Status</h5>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Status</p>

                                    {accountStatus?.account_status === AccountStatus.Active && <h6 className="text-green">Active</h6>}
                                    {accountStatus?.account_status === AccountStatus.Closed && <h6 className="text-red"> Closed </h6>}
                                  </div>

                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Date</p>
                                    {/* <h6>N/A</h6> */}
                                    {accountStatus?.account_status_updated_at === "" || accountStatus?.account_status_updated_at === null ? <h6> N/A </h6> : <h6>{moment(accountStatus?.account_status_created_at).format("DD/MM/YYYY")}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Reason</p>
                                    {/* <h6>N/A</h6> */}
                                    {accountStatus?.account_status_reason === "" || accountStatus?.account_status_reason === null ? <h6> N/A </h6> : <h6>{accountStatus?.account_status_reason}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Remark</p>
                                    {/* <h6>N/A</h6> */}
                                    {accountStatus?.account_status_reason === "" || accountStatus?.account_status_reason === null ? <h6> N/A </h6> : <h6>{accountStatus?.account_status_reason}</h6>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {accountStatus?.account_status === AccountStatus.Suspended && (
                            <div className="card">
                              <div className="card-header">
                                <h5 className="m-0 header"> Suspension Details</h5>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Start Date</p>
                                    {/* <h6>N/A</h6> */}
                                    {accountStatus?.account_suspended_start_date === "" || accountStatus?.account_suspended_start_date === null ? <h6> N/A </h6> : <h6>{moment(accountStatus?.account_suspended_start_date).format("DD-MM-YYYY")}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">End Date</p>
                                    {accountStatus?.account_suspended_end_date === "" || accountStatus?.account_suspended_end_date === null ? <h6> N/A </h6> : <h6>{moment(accountStatus?.account_suspended_end_date).format("DD-MM-YYYY")}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Reason</p>
                                    {accountStatus?.account_status_reason === "" || accountStatus?.account_status_reason === null ? <h6> N/A </h6> : <h6>{accountStatus?.account_status_reason}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Remark</p>
                                    {accountStatus?.remarks === "" || accountStatus?.remarks === null ? <h6> N/A </h6> : <h6>{accountStatus?.remarks}</h6>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                            <div className="card-header">
                              <h5 className="m-0 header"> Personal Details </h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Customer ID</p>
                                  <h6>{getDisplayData(userDetails?.user_cust_id)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">First Name</p>
                                  <h6>{getDisplayData(personalDetails?.personal_data_firstname)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Middle Name</p>
                                  <h6>{getDisplayData(personalDetails?.personal_data_middlename)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Last Name</p>
                                  <h6>{getDisplayData(personalDetails?.personal_data_lastname)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Email Id</p>
                                  {userDetails?.user_email_id === null || userDetails?.user_email_id === null ? (
                                    <h6> N/A </h6>
                                  ) : (
                                    <Fragment>
                                      <h6> {userDetails?.user_email_id}</h6>
                                      {userDetails?.user_email_verified === Status.VERIFY ? <a className="verified mx-2">Verified</a> : <a className="not-verified mx-2">Verify</a>}
                                    </Fragment>
                                  )}
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Mothers Maiden Name</p>
                                  <h6>{getDisplayData(personalDetails?.personal_data_mothers_maiden_name)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Date Of Birth</p>
                                  <h6>{getDisplayData(personalDetails?.personal_data_dob)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Phone Number - Primary</p>
                                  {userDetails?.user_mobile_number === null || userDetails?.user_mobile_number === null ? (
                                    <h6> N/A </h6>
                                  ) : (
                                    <h6>
                                      {userDetails?.user_mobile_number} {userDetails?.user_mobile_verified === Status.VERIFY ? <a className="verified mx-2">Verified</a> : <a className="not-verified mx-2">Verify</a>}
                                    </h6>
                                  )}
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Gender</p>
                                  <h6>{getDisplayData(personalDetails?.gender)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Marital Status</p>
                                  <h6>{getDisplayData(personalDetails?.marital_status)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Profession</p>
                                  <h6>{getDisplayData(personalDetails?.occupation)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Income</p>
                                  <h6>{getDisplayData(personalDetails?.annual_income)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Trading Experience</p>
                                  {personalDetails?.trading_experience_id === null && <h6>N/A</h6>}
                                  {personalDetails?.trading_experience_id === Experience.below_one && <h6>Less than 1 year</h6>}
                                  {personalDetails?.trading_experience_id === Experience.one_to_two && <h6>1 - 2 Years </h6>}
                                  {personalDetails?.trading_experience_id === Experience.two_to_five && <h6>2 - 5 Years</h6>}
                                  {personalDetails?.trading_experience_id === Experience.five_to_ten && <h6>5 - 10 Years</h6>}
                                  {personalDetails?.trading_experience_id === Experience.ten_to_twenty && <h6>10 - 20 Years</h6>}
                                  {personalDetails?.trading_experience_id === Experience.twenty_to_twentyfive && <h6>20 - 25 Years</h6>}
                                  {personalDetails?.trading_experience_id === Experience.above_twentyfive && <h6>Above 25 Years</h6>}
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">IPV</p>
                                  {panDetails != undefined && panDetails.cvl_kra_ipv_verified != undefined && panDetails.cvl_kra_ipv_verified === 1 ? (
                                    <h6>
                                      <a className="verified mx-2">CVLKRA Verified</a>
                                    </h6>
                                  ) : (
                                    ""
                                  )}
                                  {panDetails != undefined && panDetails.cvl_kra_ipv_verified != undefined && panDetails.cvl_kra_ipv_verified === 0 ? (
                                    <>
                                      {ipvDetails?.ipv_verification_verified === PersonVideVerifyStatus.NOT_VERIFY && (
                                        <h6 onClick={()=>handledVerify(PageLinks.IPVVERIFY,userDetails?.user_id)} >
                                          {userDetails?.user_cust_id}.mp4{" "}
                                          {/* <Link to={PageLinks.IPVVERIFY} state={userDetails?.user_id} className="not-verified mx-2"> */}
                                           <span className="not-verified mx-2">Verify</span>
                                          {/* </Link>{" "} */}
                                        </h6>
                                      )}
                                      {ipvDetails?.ipv_verification_verified === PersonVideVerifyStatus.VERIFY && (
                                        <h6 onClick={()=>handledVerify(PageLinks.IPVVERIFY,userDetails?.user_id)}>
                                          {userDetails?.user_cust_id}.mp4
                                          {/* <Link to={PageLinks.IPVVERIFY} state={userDetails?.user_id} className="verified mx-2"> */}
                                           <span className="verified mx-2">Verified</span>
                                          {/* </Link> */}
                                        </h6>
                                      )}
                                      {ipvDetails?.ipv_verification_verified === PersonVideVerifyStatus.REJECT && (
                                        <h6>
                                          {userDetails?.user_cust_id}.mp4{" "}
                                          <Link to={PageLinks.IPVVERIFY} state={userDetails?.user_id} className="not-verified mx-2">
                                            Rejected
                                          </Link>{" "}
                                        </h6>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Last Update</p>
                                  {personalDetails?.personal_data_updated_at === "" || personalDetails?.personal_data_updated_at === null ? <h6> N/A </h6> : <h6>{moment(personalDetails?.personal_data_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header"> PAN Details / GST Details / KRA Status </h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">PAN</p>
                                  {panDetails?.cvl_kra_pan_number != null ? (
                                    <h6>
                                      {panDetails.cvl_kra_pan_number}
                                      {<a className="verified mx-2">Verified</a>}
                                      {/* {panDetails?.cvl_kra_ipv_verified === Status.NOT_VERIFY && (
                                        <a
                                          //  to={PageLinks.IPVVERIFY} state={userDetails?.user_id}
                                          className="verified mx-2"
                                        >
                                          Verified
                                        </a>
                                      )} */}
                                    </h6>
                                  ) : (
                                    <h6>N/A</h6>
                                  )}
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">PAN Name (As per Record)</p>
                                  {/* <h6>{!personalDetails?.pan_name && "N/A"}</h6> */}
                                  {panDetails?.cvl_kra_pan_name === "" || panDetails?.cvl_kra_pan_name === null ? <h6> N/A </h6> : <h6>{panDetails?.cvl_kra_pan_name}</h6>}
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Father Name (As per Record)</p>
                                  {panDetails?.cvl_kra_father_name === "" || panDetails?.cvl_kra_father_name === null ? <h6> N/A </h6> : <h6>{panDetails?.cvl_kra_father_name}</h6>}
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Aadhaar Seeding Status</p>
                                  {panDetails?.cvl_kra_aadhar_verified === null ? <h6> N/A </h6> : <h6>{panDetails?.cvl_kra_aadhar_verified === Yes_or_No.YES ? "Yes" : "No"}</h6>}
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">IPV Seeding Status</p>
                                  {panDetails?.cvl_kra_ipv_verified === null ? <h6> N/A </h6> : <h6>{panDetails?.cvl_kra_ipv_verified === Yes_or_No.YES ? "Yes" : "No"}</h6>}
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">GST Available</p>
                                  {Cvl_kraAddresDetails?.cvl_kra_gst_available === "" || Cvl_kraAddresDetails?.cvl_kra_gst_available === null ? <h6> N/A </h6> : <h6>{Cvl_kraAddresDetails?.cvl_kra_gst_available}</h6>}
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">GST No</p>
                                  {Cvl_kraAddresDetails?.cvl_kra_gst_number === "" || Cvl_kraAddresDetails?.cvl_kra_gst_number === null ? <h6> N/A </h6> : <h6>{Cvl_kraAddresDetails?.cvl_kra_gst_number}</h6>}
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">API Type</p>
                                  {panDetails?.cvl_kra_api_type === "" || panDetails?.cvl_kra_api_type === null ? <h6> N/A </h6> : <h6>{panDetails?.cvl_kra_api_type}</h6>}
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Last Update</p>
                                  {panDetails?.cvl_kra_updated_at === "" || panDetails?.cvl_kra_updated_at === null ? <h6> N/A </h6> : <h6>{moment(panDetails?.cvl_kra_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header"> Aadhaar Details </h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Aadhaar Number</p>
                                  {personalDetails?.personal_data_aadhar_number != null ? (
                                    <h6>
                                      {personalDetails?.personal_data_aadhar_number}
                                      {personalDetails?.personal_data_aadhar_verified === Status.VERIFY && <a className="verified mx-2">Verified</a>}
                                      {personalDetails?.personal_data_aadhar_verified === Status.NOT_VERIFY && (
                                        <Link to={PageLinks.IPVVERIFY} className="verified mx-2">
                                          Verified
                                        </Link>
                                      )}
                                    </h6>
                                  ) : (
                                    <h6>N/A</h6>
                                  )}
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Name (As per Record) </p>
                                  <h6>{getDisplayData(aadharDetails?.digilocker_aadhaar_fullname)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Date Of Birth</p>
                                  <h6>{getDisplayData(aadharDetails?.digilocker_aadhaar_dob)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Gender</p>
                                  <h6>{getDisplayData(aadharDetails?.gender)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">API Type </p>
                                  <h6>{getDisplayData(aadharDetails?.digilocker_aadhaar_api_type)}</h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Type (As per Aadhaar)</p>
                                                                    {!digilocker_aAddresDetails?.address_type && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.address_type === "" || digilocker_aAddresDetails?.address_type === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.address_type}</h6>}
                                                                </div> */}

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Address Line 1</p>
                                  <h6>{getDisplayData(aadharDetails?.digilocker_aadhar_address_line_1)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Address Line 2</p>
                                  <h6>{getDisplayData(aadharDetails?.digilocker_aadhar_address_line_2)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">City</p>
                                  <h6>{getDisplayData(aadharDetails?.digilocker_aadhaar_village_town_city)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">State</p>
                                  <h6>{getDisplayData(aadharDetails?.digilocker_aadhaar_state)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Pincode</p>
                                  <h6>{getDisplayData(aadharDetails?.digilocker_aadhaar_pincode)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Last Update</p>
                                  <h6>{getFormattedLocalDateTime(aadharDetails?.digilocker_aadhaar_updated_at)}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header"> Updated Address</h5>
                            </div>
                            {updateAddress != undefined && updateAddress.length > 0 ? (
                              updateAddress.map((address: any, index: number) => {
                                return (
                                  <div className="card-body" key={index}>
                                    <div className="row">
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Address Line 1</p>
                                        {address.personal_data_address_line_1 === "" || address.personal_data_address_line_1 === null ? <h6> N/A </h6> : <h6>{address.personal_data_address_line_1.split("null")}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Address Line 2</p>
                                        {address.personal_data_address_line_2 === "" || address.personal_data_address_line_2 === null ? <h6> N/A </h6> : <h6>{address.personal_data_address_line_2.split("null")}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">State</p>
                                        {address.personal_data_address_state === "" || address.personal_data_address_state === null ? <h6> N/A </h6> : <h6>{address.personal_data_address_state}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">City</p>
                                        {address.personal_data_address_city === "" || address.personal_data_address_city === null ? <h6> N/A </h6> : <h6>{address.personal_data_address_city}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Pincode</p>
                                        {address.personal_data_address_pincode === "" || address.personal_data_address_pincode === null ? <h6> N/A </h6> : <h6>{address.personal_data_address_pincode}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Last Update</p>
                                        {address.personal_data_updated_at === "" || address.personal_data_updated_at === null ? <h6> N/A </h6> : <h6>{moment(address.personal_data_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3"> </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="card-body">
                                <h5>No data found</h5>
                              </div>
                            )}
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header"> Nominee Details</h5>
                            </div>
                            {nominee_details != undefined && nominee_details.length > 0
                              ? nominee_details.map((nominee: APIData.NomineeDetails, inx: number) => {
                                  return (
                                    <div className="card-body">
                                      <h5 className="m-0 header py-4"> Nominee {inx + 1}</h5>
                                      <div className="row">
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Percentage</p>
                                          <h6>{getDisplayData(nominee?.nominee_percentage)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Title</p>
                                          <h6>{getDisplayData(nominee?.name_title)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Full Name</p>
                                          <h6>{getDisplayData(nominee?.nominee_fullname)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Relationship with Application</p>
                                          <h6>{getDisplayData(nominee?.relationship_with_applicant)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Date Of Birth</p>
                                          <h6>{getDisplayData(nominee?.nominee_dob)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Phone Number - Primary</p>
                                          <h6>{getDisplayData(nominee?.nominee_mobile_number)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">
                                            {nominee?.nominee_identification_id == 1 && <span className="my-account-input-title">PAN Number</span>}
                                            {nominee?.nominee_identification_id == 2 && <span className="my-account-input-title">Aadhar Card Number</span>}
                                            {nominee?.nominee_identification_id == 3 && <span className="my-account-input-title">Voter ID Number</span>}
                                            {nominee?.nominee_identification_id == 4 && <span className="my-account-input-title">Driving License Number</span>}
                                            {nominee?.nominee_identification_id == 5 && <span className="my-account-input-title">Passport</span>}
                                          </p>
                                          <h6>{getDisplayData(nominee?.nominee_identification_number)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Address Line 1</p>
                                          <h6>{getDisplayData(nominee?.nominee_address_line_1)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Address Line 2</p>
                                          <h6>{getDisplayData(nominee?.nominee_address_line_2)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">State</p>
                                          <h6>{getDisplayData(nominee?.nominee_address_state)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">City</p>
                                          <h6>{getDisplayData(nominee?.nominee_address_city)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Pincode</p>
                                          <h6>{getDisplayData(nominee?.nominee_address_pincode)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Created at</p>
                                          <h6>{getFormattedLocalDateTime(nominee?.nominee_created_at)}</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Last Update</p>
                                          <h6>{getFormattedLocalDateTime(nominee?.nominee_updated_at)}</h6>
                                        </div>
                                      </div>
                                      {nominee?.nominee_guardian_records != undefined && nominee?.nominee_guardian_records.length > 0
                                        ? nominee?.nominee_guardian_records.map((guard: APIData.guardianRecords, index: number) => {
                                            return (
                                              <>
                                                <h5 className="m-0 header py-4"> Guardian {index + 1}</h5>
                                                <div className="row">
                                                  {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Percentage</p>
                                                    <h6>{getDisplayData(guard?.nominee_percentage)}</h6>
                                                  </div> */}
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Title</p>
                                                    <h6>{getDisplayData(guard?.name_title)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Full Name</p>
                                                    <h6>{getDisplayData(guard?.nominee_guardian_fullname)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Relationship with Application</p>
                                                    <h6>{getDisplayData(guard?.relationship_with_applicant)}</h6>
                                                  </div>
                                                  {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Date Of Birth</p>
                                                    <h6>{getDisplayData(guard?.nominee_dob)}</h6>
                                                  </div> */}
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Phone Number - Primary</p>
                                                    <h6>{getDisplayData(guard?.nominee_guardian_mobile_number)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">
                                                      {guard?.nominee_identification_id == 1 && <span className="my-account-input-title">PAN Number</span>}
                                                      {guard?.nominee_identification_id == 2 && <span className="my-account-input-title">Aadhar Card Number</span>}
                                                      {guard?.nominee_identification_id == 3 && <span className="my-account-input-title">Voter ID Number</span>}
                                                      {guard?.nominee_identification_id == 4 && <span className="my-account-input-title">Driving License Number</span>}
                                                      {guard?.nominee_identification_id == 5 && <span className="my-account-input-title">Passport</span>}
                                                    </p>
                                                    <h6>{getDisplayData(guard?.nominee_guardian_identification_number)}</h6>
                                                  </div>
                                                  {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Address Line 1</p>
                                                    <h6>{getDisplayData(guard?.nominee_address_line_1)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Address Line 2</p>
                                                    <h6>{getDisplayData(guard?.nominee_address_line_2)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">State</p>
                                                    <h6>{getDisplayData(guard?.nominee_address_state)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">City</p>
                                                    <h6>{getDisplayData(guard?.nominee_address_city)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Pincode</p>
                                                    <h6>{getDisplayData(guard?.nominee_address_pincode)}</h6>
                                                  </div> */}
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Address Line 1</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_line_1)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Address Line 2</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_line_2)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">State</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_state)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">City</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_city)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Pincode</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_pincode)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Created at</p>
                                                    <h6>{getFormattedLocalDateTime(guard?.nominee_identification_created_at)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Last Update</p>
                                                    <h6>{getFormattedLocalDateTime(guard?.nominee_guardian_updated_at)}</h6>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          })
                                        : ""}
                                    </div>
                                  );
                                })
                              : "No Data Found"}
                          </div>

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header"> FACTA Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Where were you born?</p>
                                                                                    {items.born_place === "" || items.born_place === null ? <h6> N/A </h6> : <h6>{items.born_place}</h6>}
                                                                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Are you a citizen of the U.S.A?</p>
                                  {userDetails?.user_citizen_usa === null ? <h6>N/A</h6> : ""}
                                  {userDetails?.user_citizen_usa == Yes_or_No.YES ? <h6>Yes</h6> : ""}
                                  {userDetails?.user_citizen_usa == Yes_or_No.NO ? <h6>No</h6> : ""}
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Are You A Tax Resident Of Any Other Country Other Than India?</p>
                                  <div>
                                    {userDetails?.user_tax_resident === null ? <h6>N/A</h6> : ""}
                                    {userDetails?.user_tax_resident == Yes_or_No.YES ? <h6>Yes</h6> : ""}
                                    {userDetails?.user_tax_resident == Yes_or_No.NO ? <h6>No</h6> : ""}
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Have You Filed ITR For Last 2 Years</p>
                                  <div>
                                    {userDetails?.user_itr_records === null ? <h6>N/A</h6> : ""}
                                    {userDetails?.user_itr_records == Yes_or_No.YES ? <h6>Yes</h6> : ""}
                                    {userDetails?.user_itr_records == Yes_or_No.NO ? <h6>No</h6> : ""}
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Address Type</p>
                                  <div>
                                    {aadharDetails?.facat_address_type == undefined || aadharDetails?.facat_address_type === null ? <h6>N/A</h6> : ""}
                                    {aadharDetails?.facat_address_type == FACTA_Address_Type.Residential? <h6>Residential</h6> : ""}
                                    {aadharDetails?.facat_address_type == FACTA_Address_Type.Business? <h6>Business</h6> : ""}
                                    {aadharDetails?.facat_address_type == FACTA_Address_Type.Residential_Bussiness? <h6>Residential or Business</h6> : ""}
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">City of Birth</p>
                                  <div>
                                    {getDisplayData(aadharDetails?.digilocker_aadhaar_district)}
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Country of Birth</p>
                                  <div>
                                  {getDisplayData(aadharDetails?.digilocker_aadhaar_country)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                            {bankDetails.length > 0 && (
                              <>
                                <div className="card">
                                  <div className="card-header">
                                    <h5 className="m-0 header"> BANK Details</h5>
                                  </div>
                                  {bankDetails.map((banks_details: APIData.bankDetails, i: any) => {
                                    return (
                                      <div className="card-body" key={i}>
                                        <div className="row">
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Status</p>
                                            {banks_details.bank_is_primary === Yes_or_No.YES ? <h6 className="text-green">Active</h6> : <h6 className="text-red">In Active</h6>}
                                          </div>
                                          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Name (as per Bank Records)</p>
                                          {!banks_details.bank_account_holder_name && <h6>N/A</h6>}
                                          {banks_details.bank_account_holder_name === "" || banks_details.bank_account_holder_name === null ? (
                                            <h6>N/A</h6>
                                          ) : (
                                            <h6>{banks_details.bank_account_holder_name}</h6>
                                          )}
                                        </div> */}
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Bank Name</p>
                                            {banks_details.bank_name === "" || banks_details.bank_name === null ? <h6>N/A</h6> : <h6>{banks_details.bank_name}</h6>}
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Branch Name</p>
                                            {banks_details.bank_branch_name === "" || banks_details.bank_branch_name === null ? <h6>N/A</h6> : <h6>{banks_details.bank_branch_name}</h6>}
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Account Number</p>
                                            {banks_details.bank_account_number === "" || banks_details.bank_account_number === null ? <h6>N/A</h6> : <h6>{banks_details.bank_account_number}</h6>}
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">IFSC Code</p>
                                            {banks_details.bank_ifsc_code === "" || banks_details.bank_ifsc_code === null ? <h6>N/A</h6> : <h6>{banks_details.bank_ifsc_code}</h6>}
                                          </div>

                                          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Branch Address</p>
                                          {banks_details.bank_branch_address === "" || banks_details.bank_branch_address === null ? <h6>N/A</h6> : <h6>{banks_details.bank_branch_address}</h6>}
                                        </div>

                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Account Type 1</p>
                                          {banks_details.bank_account_type_1 === 0 || banks_details.bank_account_type_1 === null ? (
                                            <h6>N/A</h6>
                                          ) : (
                                            <h6>{banks_details.bank_account_type_1 === 1 ? "Saving" : "Current"}</h6>
                                          )}
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Account Type 2</p>
                                          {banks_details.bank_account_type_2 === 0 || banks_details.bank_account_type_2 === null ? (
                                            <h6>N/A</h6>
                                          ) : (
                                            <h6>{banks_details.bank_account_type_2 === 1 ? "Single" : "Current"}</h6>
                                          )}
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                          <p className="detail-title">Last Update</p>
                                          {banks_details?.bank_updated_at === "" || banks_details?.bank_updated_at === null ? (
                                            <h6> N/A </h6>
                                          ) : (
                                            <h6>{moment(banks_details?.bank_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>
                                          )}
                                        </div> */}
                                          <div className="col-sm-9 col-md-9 col-lg-9"></div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            )}

                            <br />
                            <div className="card">
                              <div className="card-header">
                                <h5 className="m-0 header">Demat Details</h5>
                              </div>
                              <div className="card-body">
                                {demantDetails != undefined &&
                                  demantDetails.length > 0 &&
                                  demantDetails.map((res: APIData.demantDetails, i: any) => {
                                    return (
                                      <>
                                        <div className="row">
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3" key={i}>
                                            <p className="detail-title">Status</p>
                                            {/* <h6>{!res.status && "N/A"}</h6>
                                                                                {res?.status === null ? <h6> N/A </h6> : <h6>{res?.status}</h6>} */}
                                            {res?.demat_status === Yes_or_No.YES ? <h6 className="text-green">Active</h6> : <h6 className="text-red">In Active</h6>}
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Depository</p>
                                            {/* {res?.depository === "" || res?.depository === null ? <h6> N/A </h6> : <h6>{Number(res?.depository) === Depository.CSDL ? "CSDL" : "NSDL"}</h6>} */}
                                            <h6>{getDisplayData(res.depository)}</h6>
                                          </div>
                                          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">DP Name</p>
                                        {res?.dp_name === "" || res?.dp_name === null ? <h6> N/A </h6> : <h6>{res?.dp_name}</h6>}
                                      </div> */}
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">DP ID</p>
                                            {res?.dp_id === "" || res?.dp_id === null ? <h6> N/A </h6> : <h6>{res?.dp_id}</h6>}
                                          </div>
                                          {res.depository_id === Depository.CSDL ? (
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Client ID</p>
                                              <h6>{getDisplayData(res.client_id)}</h6>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Last Update</p>
                                            {res?.last_update === "" || res?.last_update === null ? <h6> N/A </h6> : <h6>{moment(res?.last_update).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                          </div>
                                          {/* <div className="col-sm-12 col-md-12 col-lg-12"></div> */}
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>

                            <br />
                            <div className="card">
                              <div className="card-header">
                                <h5 className="m-0 header">Account Details</h5>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Registration Date</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Empanelment Date</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Empanelment Frequency</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Empanelment With</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Net worth</p>
                                    <h6>N/A</h6>
                                  </div>

                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">AUM</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Custodian Segment</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Custodian Settlement</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Settlement Mode</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Customer for IPO Only</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">IPO Category</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">IPO SUB Category</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">NSCCL & ICCL UCC</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Client Type</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">ITR Submitted</p>
                                    <h6>N/A</h6>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Remarks</p>
                                    <h6>N/A</h6>
                                  </div>

                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Last Update</p>
                                    <h6>N/A</h6>
                                    {/* {items?.demat_account_details_updated_at === "" || items?.demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(items?.demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>} */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <br />
                            <div className="card">
                              <div className="card-header">
                                <h5 className="m-0 header">Document Details</h5>
                              </div>
                              <div className="card-body">
                                <div className="table-rep-plugin">
                                  <div className="table-responsive " data-pattern="priority-columns">
                                    <table id="tech-companies-1" className="table table table-bordered">
                                      <thead className="city-table">
                                        <tr>
                                          <th className="text-center">Sr.No</th>
                                          <th className="text-center">Upload Date</th>
                                          <th className="text-center">Document Name</th>
                                          <th className="text-center">Uploads</th>
                                          <th className="text-center">Verified</th>
                                          <th className="text-center">Remarks</th>
                                          <th className="text-center">Download</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <tr>
                                          {typeof documentDetails?.esign_pdf != "undefined" && Object.keys(documentDetails?.esign_pdf).length != 0 && (
                                            <>
                                              <td className="text-center">1 </td>
                                              <td className="text-center">{getFormattedLocalDate(documentDetails?.esign_pdf.last_update)}</td>
                                              <td className="text-left">{getDisplayData(documentDetails?.esign_pdf.document_name)}</td>
                                              <td className="text-left text-decoration-underline">{getDownloadURLExtension(documentDetails?.esign_pdf.user_application_form)}</td>
                                              <td className="text-center">{documentDetails?.esign_pdf.user_application_form_status === 1 ? <span className="text-green">Yes</span> : <span className="text-red">No</span>}</td>
                                              <td className="text-center">N/A</td>
                                              <td className="text-center">
                                                <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => getDownloadFile(documentDetails?.esign_pdf.user_application_form)}></i>{" "}
                                              </td>
                                            </>
                                          )}
                                        </tr>

                                        <tr>
                                          {typeof documentDetails?.address_proof_details != "undefined" && Object.keys(documentDetails?.address_proof_details).length != 0 && (
                                            <>
                                              <td className="text-center">2</td>
                                              <td className="text-center">{getFormattedLocalDate(documentDetails?.address_proof_details.last_update)}</td>
                                              <td className="text-left">{getDisplayData(documentDetails?.address_proof_details.document_name)}</td>
                                              <td className="text-left text-decoration-underline">
                                                {getDownloadURLExtension(documentDetails?.address_proof_details.address_proof_front_image)}
                                                <br />
                                                {getDownloadURLExtension(documentDetails?.address_proof_details.address_proof_back_image)}
                                              </td>
                                              <td className="text-center">{documentDetails?.address_proof_details.address_proof_verified === 1 ? <span className="text-green">Yes</span> : <span className="text-red">No</span>}</td>
                                              <td className="text-center">N/A</td>
                                              <td className="text-center">
                                                <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => getDownloadFile(documentDetails?.address_proof_details.address_proof_front_image)}></i>
                                                <br />
                                                <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => getDownloadFile(documentDetails?.address_proof_details.address_proof_back_image)}></i>
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                        <tr>
                                          {documentDetails?.bank_details !== undefined &&
                                            documentDetails?.bank_details.length > 0 &&
                                            documentDetails?.bank_details.map((bank: APIData.bank, index: number) => {
                                              return (
                                                <>
                                                  <td className="text-center">{index + 3}</td>
                                                  <td className="text-center">{getFormattedLocalDate(bank.last_update)}</td>
                                                  <td className="text-left">{getDisplayData(bank.document_name)}</td>
                                                  <td className="text-left text-decoration-underline">{getDownloadURLExtension(bank.bank_cancel_cheque_image)}</td>
                                                  <td className="text-center">{bank.bank_is_verified === 1 ? <span className="text-green">Yes</span> : <span className="text-red">No</span>}</td>
                                                  <td className="text-center">N/A</td>
                                                  <td className="text-center">
                                                    <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(bank.bank_cancel_cheque_image)}></i>{" "}
                                                  </td>
                                                </>
                                              );
                                            })}
                                        </tr>

                                        {/* {documentProofDetails && (
                                        <tr>
                                          {(documentProofDetails.proof_front_image !== "" || documentProofDetails.proof_front_image !== null) && (
                                            <>
                                              <td className="text-center">02 </td>
                                              <td className="text-center">
                                                {documentProofDetails.personal_details_updated_at !== "" || documentProofDetails.personal_details_updated_at !== null ? (
                                                  <> {moment(documentProofDetails.personal_details_updated_at).format("DD-MM-YYYY")}</>
                                                ) : (
                                                  <> N/A </>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null ? <> {documentProofDetails.proof_type} Front</> : <> N/A </>}
                                              </td>
                                              <td className="text-center text-decoration-underline">
                                                {documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null ? <> {documentProofDetails.proof_type} Front.Pdf</> : <> N/A </>}
                                              </td>
                                              <td className="text-center">
                                                <span className="text-green">Yes</span>
                                              </td>
                                              <td className="text-center">N/A</td>
                                              <td className="text-center">
                                                <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(documentProofDetails.proof_front_image)}></i>{" "}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      )}

                                      {documentProofDetails && (
                                        <tr>
                                          {(documentProofDetails.proof_back_image !== "" || documentProofDetails.proof_back_image !== null) && (
                                            <>
                                              <td className="text-center">03 </td>
                                              <td className="text-center">
                                                {documentProofDetails.personal_details_updated_at !== "" || documentProofDetails.personal_details_updated_at !== null ? (
                                                  <> {moment(documentProofDetails.personal_details_updated_at).format("DD-MM-YYYY")}</>
                                                ) : (
                                                  <> N/A </>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null ? <> {documentProofDetails.proof_type} Back</> : <> N/A </>}
                                              </td>
                                              <td className="text-center text-decoration-underline">
                                                {documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null ? <> {documentProofDetails.proof_type} Back.Pdf</> : <> N/A </>}
                                              </td>
                                              <td className="text-center">
                                                <span className="text-green">Yes</span>
                                              </td>
                                              <td className="text-center">N/A</td>
                                              <td className="text-center">
                                                <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(documentProofDetails.proof_back_image)}></i>{" "}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      )}

                                      {documentDetails.demat_signature && (
                                        <tr>
                                          {(documentDetails["demat_signature"].demat_signature !== "" || documentDetails["demat_signature"].demat_signature !== null) && (
                                            <>
                                              <td className="text-center">04 </td>
                                              <td className="text-center">
                                                {documentDetails["demat_signature"].demat_account_details_updated_at !== "" ||
                                                documentDetails["demat_signature"].demat_account_details_updated_at !== null ? (
                                                  <> {moment(documentDetails["demat_signature"].demat_account_details_updated_at).format("DD-MM-YYYY")}</>
                                                ) : (
                                                  <> N/A </>
                                                )}
                                              </td>
                                              <td className="text-center">Demat Signature</td>
                                              <td className="text-center text-decoration-underline">Demat Signature.pdf </td>
                                              <td className="text-center">
                                                <span className="text-green">Yes</span>
                                              </td>
                                              <td className="text-center">N/A</td>
                                              <td className="text-center">
                                                <i
                                                  className="fa fa-download cursor-pointer"
                                                  aria-hidden="true"
                                                  onClick={() => window.location.replace(documentDetails["demat_signature"].demat_signature)}
                                                ></i>{" "}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      )} */}
                                        {/* <tr>
                                                                                <td className="text-center">03 </td>
                                                                                <td className="text-center">30-08-2022</td>
                                                                                <td className="text-center">Aadhaar Card</td>
                                                                                <td className="text-center">AadhaarCard.jpg</td>
                                                                                <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                <td className="text-center"></td>
                                                                                <td className="text-center"><i className="fa fa-download" aria-hidden="true"></i>   </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">04 </td>
                                                                                <td className="text-center">30-08-2022</td>
                                                                                <td className="text-center">Cancelled Cheque</td>
                                                                                <td className="text-center">CancelledCheque.jpg</td>
                                                                                <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                <td className="text-center"></td>
                                                                                <td className="text-center"><i className="fa fa-download" aria-hidden="true"></i>   </td>
                                                                            </tr> */}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <br />
                            {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header"> Dealer Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Dealer Type</p>
                                  <h6>N/A</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Dealer Name</p>
                                  <h6>N/A</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">From Date</p>
                                  <h6>N/A</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">From Date</p>
                                  <h6>N/A</h6>
                                </div>
                              </div>
                            </div>
                          </div> */}
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <AccountStatusModal userId={userId} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
        {/* <SimpleModal contents={res.suspended_remarks} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}
        <PreviewJSONModal contents={cvlkraJSON} closeModel={() => setOpenJSON(false)} showModel={openJSON} />
      </div>
    </>
  );
};

export default UsersDetails;
