import Pagination from '@mui/material/Pagination';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { DealerStatus, Depository, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { InitialData } from '../common/InitialValue';
import { frameCustomerAddress, getDealerStatus, getDisplayData, getDownloadFile, getFormattedLocalDate, getFormattedLocalDateTime, getGender, getOrderStatus, numberWithCommas } from '../common/Utilities';
import toast from 'react-hot-toast';

const CustomerOrderDetailView: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [customerorderDetail, setCustomerorderDetail] = useState<APIData.CustomerOrderDetailView>(InitialData.CustomerOrderDetailView);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<any>();
    const formikRef = useRef<FormikProps<APIData.BondAdd>>(null);

    useEffect(() => {
        console.log(location.state)
        if (location.state) {
            loadOrderListDetailView(location.state.type, location.state.id);
        }
    }, [])

    const loadOrderListDetailView = (type: number, id: number) => {
        setShowPreloader(true);
        ServerAPI.getOrderListDetailView(type, id).then((res) => {
            setCustomerorderDetail(res);
            setTotalCount(res.total_count);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Order Book </a></li>
                                                <li className="breadcrumb-item active "> Detail View </li>
                                            </ol>
                                        </div>
                                        <div className='cursor-pointer' onClick={() => navigate(-1)}>
                                            <p className='mb-0 active'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                                                <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                                            </svg>
                                                <span> Back </span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header col-xl-12 w-100">
                                            <h5 className="my-3 header"> Order Book Details </h5>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='col-lg-8' style={{ margin: '5px' }}>
                                                    <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                        {/* <div className="card-header">
                                                            <h5 className="m-0 header"> Personal Details </h5>
                                                        </div> */}
                                                        <div className="card-body">
                                                            <div className="row d-flex justify-content-between mb-5">
                                                                <div className='col-lg-6'>
                                                                    <div className="d-flex">
                                                                        <img src={customerorderDetail?.bond_detail.bond_logo} style={{ width: "50px" }}></img>
                                                                        <h5 style={{ marginLeft: '15px' }}>{getDisplayData(customerorderDetail?.bond_detail.bond_name)}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6' style={{ textAlign: 'end' }}>
                                                                    <h5>Order No: {getDisplayData(customerorderDetail?.order_id)}</h5>
                                                                    <h6>{getFormattedLocalDateTime(customerorderDetail?.ipo_order_created_at)}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: "50px" }}>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Application No</p>
                                                                    {/* <h6>{getDisplayData(customerorderDetail.dealer_unique_code)}</h6> */}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Date Assigned</p>
                                                                    <h6>{getFormattedLocalDate(customerorderDetail?.ipo_order_datetime)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">No. Of Quantity</p>
                                                                    <h6>{getDisplayData(customerorderDetail?.ipo_order_quantity)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Total Amount</p>
                                                                    <h6>{numberWithCommas(customerorderDetail?.ipo_order_total_amount)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Order Status</p>
                                                                    <h6>{getOrderStatus(customerorderDetail?.ipo_order_status[0]?.ipo_order_status)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Payment Status</p>
                                                                    <h6>{getDisplayData(customerorderDetail?.ipo_order_payments[0]?.ipo_order_payment_status)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Download Form</p>
                                                                    <img src='../../assets/images/download.svg' style={{ width: '15px', margin: "0px 5px" }}
                                                                        onClick={() => getDownloadFile(customerorderDetail?.ipo_order_pdf)}
                                                                    ></img>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Print Form</p>
                                                                    <img src='../../assets/images/print.svg' style={{ width: '15px', margin: "0px 5px" }}></img>
                                                                </div>
                                                                <div className="col-lg-12 col-md-4 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                                                    <a className='save-btn' style={{ padding: '10px' }}>Download Invoice <img src='../../assets/images/download-invoice.svg' style={{ width: '15px', margin: "0px 5px" }}></img></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4' style={{ margin: '5px' }}>
                                                    <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                        <div className="card-header">
                                                            <h5 className="m-0 header"> Customer Details </h5>
                                                        </div>
                                                        <div className="card-body">

                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Customer ID</p>
                                                                    <h6>{getDisplayData(customerorderDetail?.customer_details.customer_id)}</h6>
                                                                </div>
                                                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Name</p>
                                                                    <h6>{getDisplayData(customerorderDetail?.customer_details.fname)}</h6>
                                                                </div>
                                                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Email ID</p>
                                                                    <h6>{getDisplayData(customerorderDetail?.customer_details.email_id)}</h6>
                                                                </div>
                                                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Mobile No</p>
                                                                    <h6>{getDisplayData(customerorderDetail?.customer_details.mobile_number)}</h6>
                                                                </div>
                                                                <div className="col-lg-12 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address</p>
                                                                    <h6>{frameCustomerAddress(
                                                                        customerorderDetail?.customer_details.address_line_1,
                                                                        customerorderDetail?.customer_details.address_line_2,
                                                                        customerorderDetail?.customer_details.address_line_3,
                                                                        customerorderDetail?.customer_details.address_city,
                                                                        customerorderDetail?.customer_details.address_state,
                                                                        customerorderDetail?.customer_details.address_zip)}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />

                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="m-0 header">Transactions Details</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Payment Mode</p>
                                                            <h6>{getDisplayData(customerorderDetail?.ipo_order_payments[0]?.payment_mode_id)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Transaction ID</p>
                                                            <h6>n/a</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Date</p>
                                                            <h6>{getFormattedLocalDateTime(customerorderDetail?.ipo_order_payments[0]?.ipo_order_payment_datetime)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Status</p>
                                                            <h6>{getDisplayData(customerorderDetail?.ipo_order_payments[0]?.ipo_order_payment_status)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Amount</p>
                                                            <h6>{getDisplayData(customerorderDetail?.ipo_order_payments[0]?.ipo_order_payment_amount)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="m-0 header">Transactions Bank Account Details</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Bank Name</p>
                                                            <h6>{getDisplayData(customerorderDetail?.bank_details.bank_name)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Account No</p>
                                                            <h6>{getDisplayData(customerorderDetail?.bank_details.account_number)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Account Type</p>
                                                            <h6>{getDisplayData(customerorderDetail?.bank_details.account_type_1)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Branch Name</p>
                                                            <h6>{getDisplayData(customerorderDetail?.bank_details.branch_name)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">IFSC Code</p>
                                                            <h6>{getDisplayData(customerorderDetail?.bank_details.ifsc_code)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default CustomerOrderDetailView;

function getOrderListDetailView(type: any, id: any) {
    throw new Error('Function not implemented.');
}

