import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import moment from "moment";
// import { constants } from 'buffer';
import React, { useState, useEffect } from "react";
import DatePicker from "react-date-picker";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { FormDataTypes } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { frameSelectOptions } from "../common/Utilities";
import ConfirmationModal from "./Confirmation-modal";

interface modalDatas {
  openModal?: any;
  handleClose?: any;
  handleOpen?: any;
  userId?: any;
  // handleOpen
}

const SuspendedModal: React.FC<modalDatas> = ({ handleOpen, openModal, handleClose, userId }) => {
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [toData, setToData] = useState<any>();
  const [fromDate, setFromDate] = useState<any>();
  const [update_datas, setUpdateDatas] = useState({
    account_status: 3,
    account_status_reason: "",
    remarks_id: 0,
  });

  const [all_datas, setAllDatas] = useState<any>();
  const [remarkOption, setRemarkOption] = useState<FormDataTypes.SelectOption[]>([]);
  const { setShowPreloader } = useAppStateAPI();

  useEffect(() => {
    if (openModal === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    loadRemarkList();
  }, [openModal]);

  const loadRemarkList = () => {
    setShowPreloader(true);
    ServerAPI.RemarkList()
      .then((res) => {
        let remOptions = frameSelectOptions(res.message, "remarks_id", "remarks");
        setRemarkOption(remOptions);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleCloses = () => {
    setOpen(false);
    setOpenModal(false);
    handleClose(false);
  };

  const handleClosed = (e: any) => {
    setOpenModal(e);
    setOpen(e);
    handleClose(e);
  };

  const save_modal = () => {
    console.log(update_datas);
    const startdate = new Date(fromDate);
    const enddate = new Date(toData);
    if (startdate > enddate) {
      toast.error("Start Date Should be Less Than End Date");
    } else if (fromDate == undefined || fromDate == null) {
      toast.error("Please Enter a Valid Start date");
    } else if (toData == undefined || toData == null) {
      toast.error("Please Enter a Valid End date");
    } else if (update_datas.account_status_reason == "") {
      toast.error("Please Enter a Valid Reason to Suspend User");
    } else if (update_datas.remarks_id == 0) {
      toast.error("Please Enter a Valid Remark to Suspend User");
    } else {
      let suspended_status_datas = { ...update_datas, fromDate, toData };
      setAllDatas(suspended_status_datas);
      setOpen(false);
      setOpenModal(true);
      handleClose(false);
      console.log(userId);
    }
  };

  const update_status_selection = (e: any) => {
    setUpdateDatas({
      ...update_datas,
      remarks_id: Number(e.target.value),
    });
  };

  const update_status = (e: any) => {
    setUpdateDatas({
      ...update_datas,
      account_status_reason: e.target.value,
    });
  };

  return (
    <div>
      <Dialog style={{ borderRadius: "10px" }} open={open} className="dialog-box" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className="model-title">
          <div className="modal-box-title">
            <p className="m-0">Suspension</p>
            <p className="m-0" onClick={handleCloses}>
              <i className="fa fa-times cursor-pointer font-style" aria-hidden="true"></i>
            </p>
          </div>
        </DialogTitle>
        <hr />

        <DialogContent>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <form>
              <div className="row">
                {/* <form> */}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="app-search card-title ">
                    <div className="position-relative">
                      <label>Start Date</label>
                      <DatePicker
                        className="date-picker-input"
                        maxDate={new Date()}
                        clearIcon={null}
                        closeCalendar={true}
                        name="dob"
                        format="dd/MM/yyyy"
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        onChange={setFromDate}
                        value={fromDate}
                        calendarIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 69.398 79.312">
                            <path
                              id="Path_23208"
                              data-name="Path 23208"
                              d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z"
                              transform="translate(-308.128)"
                            />
                          </svg>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="app-search card-title ">
                    <div className="position-relative">
                      <label>End Date</label>
                      <DatePicker
                        className="date-picker-input"
                        clearIcon={null}
                        closeCalendar={true}
                        name="dob"
                        format="dd/MM/yyyy"
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        onChange={setToData}
                        value={toData}
                        calendarIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 69.398 79.312">
                            <path
                              id="Path_23208"
                              data-name="Path 23208"
                              d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z"
                              transform="translate(-308.128)"
                            />
                          </svg>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <label>Select Remarks</label>
                  <select className="select-style" name="account_status_reason" onChange={(e) => update_status_selection(e)}>
                    {remarkOption.map((option: FormDataTypes.SelectOption) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 my-2">
                  <label>Reason</label>
                  <textarea
                    className=" form-control form-control-style"
                    rows={4}
                    cols={50}
                    maxLength={100}
                    placeholder="100 words max*"
                    name="account_status_remarks"
                    onChange={(e) => update_status(e)}
                  />
                </div>

                <div className="row mx-3 my-2 py-3 px-5">
                  <div className="col-lg-6">
                    <a className="cancel-btn" onClick={handleCloses}>
                      Cancel
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <a className="save-btn" onClick={() => save_modal()}>
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmationModal
        contents="Are you sure you want to suspend the account?"
        details_datas={all_datas}
        user_id={userId}
        currentPage="suspended-modal"
        openModal={open_modal}
        handleClose={(e: any) => handleClosed(e)}
      />
    </div>
  );
};

export default SuspendedModal;
