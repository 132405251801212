import moment from "moment";
import { CustomerOrderStatus, DealerStatus, Gender, PageLinks } from "./Constants";
import { useDropzone } from "react-dropzone";
import { FormDataTypes, FormDefaultConstants } from "./DataTypes";

export const persistLoginDetail = (login: boolean) => {
  localStorage.setItem("login", login ? "1" : "0");
};

export const getAuthString = () => {
  let token = localStorage.getItem("token");
  if (token == null) {
    token = "";
  }
  return token;
};

export const getIsLogin = async () => {
  const loginData = (await localStorage.getItem("login")) === "1" ? true : false;
  return loginData;
};

export const getUserName = async () => {
  const userName = await localStorage.getItem("user_name");
  return userName;
};

export const logout = (navigate: any) => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token");
  localStorage.removeItem("user_name");
  localStorage.removeItem("hashkey");
  localStorage.removeItem("login");
  navigate(PageLinks.LOGIN);
};

export const getFormattedLocalDate = (utcDateTime: any) => {
  if (utcDateTime != undefined && utcDateTime != "" && utcDateTime != null) {
    return moment.utc(utcDateTime).format("DD/MM/YYYY");
  } else {
    return "N/A";
  }
};

export const getFormattedLocalTime = (utcDateTime: any) => {
  if (utcDateTime != undefined && utcDateTime != "" && utcDateTime != null) {
    return moment.utc(utcDateTime).format("HH:MM:SS");
  } else {
    return "N/A";
  }
};

export const getFormattedLocalDateTime = (utcDateTime: any) => {
  if (utcDateTime && utcDateTime != "") {
    return moment.utc(utcDateTime).format("DD/MM/YYYY HH:MM:SS");
  }
  return "N/A";
};

// export const getFormattedLocalTime = (utcDateTime: any) => {
//     if (utcDateTime && utcDateTime != '') {
//         return moment.utc(utcDateTime).local().format('hh:mm a');
//     }
//     return 'N/A';
// }

export const getDisplaySelectOption = (value: any, key: string) => {
  if ((value != undefined && value != 0 && value != null) || (value != undefined && value != "" && value != null)) {
    if (key == "bond_nature_of_instrument") {
      var data: any = FormDefaultConstants.instrumentTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_security_type") {
      var data: any = FormDefaultConstants.securityTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_issue_status") {
      var data: any = FormDefaultConstants.issuerTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_effort_basis") {
      var data: any = FormDefaultConstants.effortTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_registrar_name") {
      var data: any = FormDefaultConstants.registatorTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_our_status") {
      var data: any = FormDefaultConstants.ourStatusTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_listing") {
      var data: any = FormDefaultConstants.listingTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_depository: number") {
      var data: any = FormDefaultConstants.depositoryTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_issuer_frequency_of_interest") {
      var data: any = FormDefaultConstants.frequnecyOfInterestTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_green_shoe") {
      var data: any = FormDefaultConstants.greenShoeTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_gst") {
      var data: any = FormDefaultConstants.GSTTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_issuers_type") {
      var data: any = FormDefaultConstants.issuerSTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_type_of_issuer") {
      var data: any = FormDefaultConstants.issuerSTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_issuer_coupon_type") {
      var data: any = FormDefaultConstants.couponTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_ncd_frequency_of_interest_payment") {
      var data: any = FormDefaultConstants.frequnceyInterestOfPaymentTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_govt_guranatee") {
      var data: any = FormDefaultConstants.GuaranteeTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_interest_type") {
      var data: any = FormDefaultConstants.InterestTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_calc_int_on_holidays") {
      var data: any = FormDefaultConstants.InterestCalculateByTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "exit_option_available") {
      var data: any = FormDefaultConstants.ExitOptionAvailableTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_calc_int_on_holidays") {
      var data: any = FormDefaultConstants.bondCalcIntOnHolidaysTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_int_on_maturity") {
      var data: any = FormDefaultConstants.bondCalcIntOnHolidaysTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_exchange") {
      var data: any = FormDefaultConstants.bond_exchangeTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_interest_frequency") {
      var data: any = FormDefaultConstants.InterestFrequencyTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_purchase_limit") {
      var data: any = FormDefaultConstants.purchaseLimitTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_ncd_put_and_option") {
      var data: any = FormDefaultConstants.putAndCallTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else if (key == "bond_ncd_mode_of_interest_payment") {
      var data: any = FormDefaultConstants.modeOFissuerTypeOption.filter((val: FormDataTypes.SelectOption) => {
        return val.value == value + "";
      });
      return data.length > 0 ? data[0].label : "N/A";
    } else {
      return "N/A";
    }
  } else {
    return "N/A";
  }
};

export const getOrderTypeFilter = (typeNo: number) => {
  if (typeNo != undefined && typeNo != 0 && typeNo != null) {
    if (typeNo == 1) {
      return "IPO";
    } else if (typeNo == 2 || typeNo == 3) {
      return "Bond";
    } else if (typeNo == 4) {
      return "SGB";
    } else {
      return "N/A";
    }
  } else {
    return "N/A";
  }
};

export const getDisplayData = (value: any) => {
  if (value != undefined && value != "" && value != null) {
    return value;
  } else {
    return "N/A";
  }
};

export const getGender = (value: number) => {
  if (value && value == Gender.FEMALE) {
    return "Female";
  } else if (value && value == Gender.MALE) {
    return "Male";
  } else if (value && value == Gender.OTHER) {
    return "Other";
  } else {
    return "N/A";
  }
};

export const getDealerStatus = (status: number) => {
  if (status && status == DealerStatus.Active) {
    return <div style={{ color: "#039F39" }}>Active</div>;
  } else {
    return <div style={{ color: "#FF0000" }}>Disable</div>;
  }
};

export const DropZone = (onDrop: any) => {
  const { getRootProps, getInputProps } = useDropzone({
    // accept: 'image/jpeg,image/png,image/jpg',
    multiple: true,
    onDrop,
    getFilesFromEvent: async (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      const promises: any = [];
      for (let index = 0; index < files.length; index++) {
        const file: any = files[index];
        const promise: any = new Promise((resolve, reject) => {
          const image = new Image();
          let url: string;
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }
      return await Promise.all(promises);
    },
    validator: (file: any) => {
      // You can access width/height properties
      if (file.width != file.height) {
        return {
          code: "dimension",
          message: `Incorrect Image Dimension. Width and Height of the image should be equal.`,
        };
      }
      return null;
    },
  });
  return { getRootProps, getInputProps };
};

export const getURLExtension = (url: string | undefined, placeholder: string) => {
  if (url != undefined && url != "") {
    if (url.split("?").pop()?.split("=").pop() == "") {
      return "N/A";
    } else {
      return url.split("?").pop()?.split("=").pop();
    }
  } else {
    return placeholder;
  }
};

export const getDownloadURLExtension = (url: string | undefined) => {
  if (url != undefined && url != "") {
    if (url.split("?").pop()?.split("=").pop() == "") {
      return "N/A";
    } else {
      return url.split("?").pop()?.split("=").pop();
    }
  } else {
    return "";
  }
};

export function numberWithCommas(x: any) {
  if (x != "" && x != null) {
    var res = parseInt(x).toLocaleString("en-IN");
    return res;
  } else {
    return "N/A";
  }
}

export function getDownloadFile(url: any) {
  if (url != "" && url != null) {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }
}

export const frameSelectOptions = (data: any[], value_param: string, label_param: string) => {
  const selectOptions: FormDataTypes.SelectOption[] = data.map((entry) => {
    return {
      value: entry[value_param] + "",
      label: entry[label_param],
    };
  });
  return selectOptions;
};

export const getOrderStatus = (status: number) => {
  if (status && status == CustomerOrderStatus.Success) {
    return <div style={{ color: "#039F39" }}>Success</div>;
  } else if (status && status == CustomerOrderStatus.Pending) {
    return <div style={{ color: "#FF0000" }}>Pending</div>;
  } else {
    return <div style={{ color: "#F29423" }}>Under Processing</div>;
  }
};

export const frameCustomerAddress = (line1: string, line2: string, landmark: string, city: string, state: string, pincode: string) => {
  let address = "";
  if (line1 && line1 != undefined) {
    address += line1 + ", ";
  }
  if (line2 && line2 != undefined) {
    address += line2 + ", ";
  }
  if (landmark && landmark != undefined) {
    address += landmark + ", ";
  }
  if (city && city != undefined) {
    address += city + ", ";
  }
  if (state && state != undefined) {
    address += state + ", ";
  }
  //Replace the last comma
  address = address.replace(/(^[,\s]+)|([,\s]+$)/g, "");
  if (pincode && pincode != undefined) {
    address += "-" + pincode;
  }
  if (address == "") {
    address = "N/A";
  }
  return address;
};

export function localStringToIST(x: string) {
  if (x != "" && x != null) {
    var dateObj = new Date(moment(x).format("ddd MMM DD YYYY h:mm:ss zZZ"));
    return dateObj;
  } else {
    return null;
  }
}
