import React, { useEffect, useState, Component, Fragment } from "react";
import { Badge } from 'customizable-react-badges';
import { Link, useNavigate } from "react-router-dom";
import { PageLinks } from "./Constants";
import { useAppStateAPI } from "./AppStateAPI";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import * as Utilities from "../common/Utilities";

const Header: React.FC = () => {

    const navigate = useNavigate();
    const { activeColor, setActiveColor, setActiveMenuColor, activeMenuColor, setActiveHeadMenuColor, activeHeadMenuColor } = useAppStateAPI();
    const [dropDownProfile, setDropDownProfile] = useState(false);
    const toggleProfile = () => setDropDownProfile(!dropDownProfile);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        Utilities.getUserName().then((status) => {
            if (status) {
                setUserName(status);
            }
        })
    }, [])

    const handleNavigationFlow = (endpoint: any, menu: string, subMenu: string) => {
        if (endpoint == PageLinks.BOND_LIST) {
            navigate(PageLinks.BOND_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.DISTRIBUTOR_LIST) {
            navigate(PageLinks.DISTRIBUTOR_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.BROKER_LIST) {
            navigate(PageLinks.BROKER_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.DEALER_LIST) {
            navigate(PageLinks.DEALER_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.SGB_LIST) {
            navigate(PageLinks.SGB_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.DISTRIBUTOR_TYPE_LIST) {
            navigate(PageLinks.DISTRIBUTOR_TYPE_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.DISTRIBUTOR_SUB_TYPE_LIST) {
            navigate(PageLinks.DISTRIBUTOR_SUB_TYPE_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.DISTRIBUTOR_CATEGORY_LIST) {
            navigate(PageLinks.DISTRIBUTOR_CATEGORY_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.BRAND_TAG_LIST) {
            navigate(PageLinks.BRAND_TAG_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.ELIGIBLE_INVENTOR_LIST) {
            navigate(PageLinks.ELIGIBLE_INVENTOR_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.BOND_BROKERAGE_CATEGORIE_LIST) {
            navigate(PageLinks.BOND_BROKERAGE_CATEGORIE_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.ASBA_LIST) {
            navigate(PageLinks.ASBA_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        } else if (endpoint == PageLinks.CUSTOMER_BOOK) {
            navigate(PageLinks.CUSTOMER_BOOK);
            setActiveColor(subMenu);
            setActiveMenuColor('bond-ipo');
            setActiveHeadMenuColor('users');
        } else if (endpoint == PageLinks.ALL_USERS_LOGS) {
            navigate(PageLinks.ALL_USERS_LOGS);
            setActiveColor(subMenu);
            setActiveMenuColor('bond-ipo');
            setActiveHeadMenuColor('user-logs');
        } else if (endpoint == PageLinks.USERS) {
            navigate(PageLinks.USERS);
            // setActiveColor(subMenu);
            setActiveMenuColor(menu);
            setActiveHeadMenuColor('users');
        } else if (endpoint == PageLinks.SUSPENDED_USER_LIST) {
            navigate(PageLinks.SUSPENDED_USER_LIST);
            // setActiveColor(subMenu);
            setActiveMenuColor(menu);
            setActiveHeadMenuColor('users');
        } else if (endpoint == PageLinks.BOND_SUBCRIBE_LIST) {
            navigate(PageLinks.BOND_SUBCRIBE_LIST);
            setActiveColor(subMenu);
            setActiveMenuColor('bond');
            setActiveHeadMenuColor('master');
        }
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'mouse-over-popover' : undefined;

    return (
        <div>
            <header id="page-topbar">
                <div className="navbar-header bg-white">
                    <div className="d-flex">
                        <div className="navbar-brand-box bg-white">
                            <a onClick={() => navigate(PageLinks.USERS)} className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src="assets/images/logo/trust_money_horizontal_logo.png" alt="" height="40px" />
                                </span>
                                <span className="logo-lg">
                                    <img src="assets/images/logo/trust_money_horizontal_logo.png" alt="logo" height="40px" />
                                </span>
                            </a>

                            <a onClick={() => navigate(PageLinks.USERS)} className="logo logo-light">
                                <span className="logo-sm">
                                    <img src="assets/images/logo/trust_money_horizontal_logo.png" alt="logo" height="40px" />
                                </span>
                                <span className="logo-lg">
                                    <img src="assets/images/logo/trust_money_horizontal_logo.png" alt="logo" height="40px" />
                                </span>
                            </a>
                        </div>

                        <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                    </div>

                    <div className="d-flex">
                        <div className="container justify-content-end">
                            <img src="../../assets/images/theme.svg" className="header-img"></img>
                            <img src="../../assets/images/notification.svg" className="header-img"></img>
                            <img src="../../assets/images/settings.svg" className="header-img"></img>
                            <div className="profile-container" onClick={toggleProfile}>
                                <img src="../../assets/images/user.png" className="profile-img" />
                                <span className={`${dropDownProfile ? 'active' : ''}`}>{userName}</span>
                                <img src="../../assets/images/dropdown.svg" className="dropDown" />
                            </div>
                            <ul className={`${dropDownProfile != true ? 'dropdown-close' : 'dropdown-open'}`}>
                                {/* <li><a><i className="mdi mdi-email-outline"></i>Messages</a></li>
                                <li><a><i className="mdi mdi-account"></i>Account</a></li>
                                <li><a><i className="mdi mdi-settings"></i>Settings</a></li> */}
                                <li><a onClick={() => { Utilities.logout(navigate); console.log(navigate) }}><i className="mdi mdi-logout"></i>Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <div className="topnav" style={{ backgroundColor: "#FBFAFF" }}>
                <div className="container-fluid ">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu " style={{ backgroundColor: "#FBFAFF" }}>
                        <div className="collapse navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown ">
                                    <a className="nav-link dropdown-toggle" id="topnav-dashboard" role="button" >
                                        <i className="fas fa-home" ></i> &nbsp;
                                        <span data-key="t-dashboards">Dashboard</span>
                                    </a>
                                </li>

                                <li className="nav-item dropdown ">
                                    <a className={`nav-link dropdown-toggle arrow-down ${activeHeadMenuColor == 'users' ? 'active-color' : ''}`} id="topnav-master" role="button">
                                        <i className="fas fa-user-circle"></i>&nbsp;
                                        <span data-key="t-dashboards">Users</span>
                                    </a>
                                    <div className="dropdown-menu " aria-labelledby="topnav-master">
                                        <div className="dropdown" >
                                            <a className={`dropdown-item dropdown-toggle arrow-none ${activeMenuColor == 'all-users' ? 'active-color' : ''}`} id="topnav-table" role="button" onClick={() => { handleNavigationFlow(PageLinks.USERS, 'all-users', '') }}>
                                                <span data-key="t-tables">All Users</span>
                                                {/* <div className="arrow-down"></div> */}
                                            </a>
                                        </div>
                                        <div className="dropdown" >
                                            <a className={`dropdown-item dropdown-toggle arrow-none ${activeMenuColor == 'suspend-users' ? 'active-color' : ''}`} id="topnav-table" role="button" onClick={() => { handleNavigationFlow(PageLinks.SUSPENDED_USER_LIST, 'suspend-users', '') }}>
                                                <span data-key="t-tables">Suspend Users</span>
                                                {/* <div className="arrow-down"></div> */}
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown ">
                                    <a className={activeHeadMenuColor === "user-logs" ? "nav-link dropdown-toggle active-color" : "nav-link dropdown-toggle"} id="topnav-dashboard" role="button" onClick={() => { handleNavigationFlow(PageLinks.ALL_USERS_LOGS, 'user-logs', 'user_logs') }}>
                                        <i className="far fa-hourglass" ></i> &nbsp;
                                        <span data-key="t-dashboards">User Logs</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}
export default Header;




