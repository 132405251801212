import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { frameSelectOptions } from "../common/Utilities";
import ImageMagnifier from "../elements/ImageMagnifier";

const IpvVerification: React.FC = () => {
  const location = useLocation();
  const videoRef: any = useRef(false);

  const user_id = location.state;
  const navigate = useNavigate();

  const [codeNumber, setCodeNumber] = useState<string>("");
  // const [videoPayer, setVideoPayer] = useState(true);
  const [IpvDatas, setIpvDatas] = useState<APIData.IpvDatas>();
  const [video_url, setVideoUrl] = useState("");
  const [aadhar_address, setAadhar_address] = useState<APIData.Digilocker_address_data>();
  const [pan_details, setPanDetails] = useState<APIData.AddressDatas_cvl_kra>();
  const [user_address, setUserAddress] = useState<APIData.UserAddress>();
  const [verify_status, setVerifyStatus] = useState(1);
  const { setShowPreloader } = useAppStateAPI();
  const [remarkOption, setRemarkOption] = useState<FormDataTypes.SelectOption[]>([]);
  const [IpvDetails, setIpvDetails] = useState<APIData.IPVDetails>();
  const [remarks, setRemarks] = useState(1);

  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.getIPVDatas(user_id)
      .then(async (res) => {
        setIpvDetails(res.message);
      })
      .finally(() => {
        setShowPreloader(false);
      });
    loadRemarkList();
  }, [user_id, video_url]);

  const loadRemarkList = () => {
    setShowPreloader(true);
    ServerAPI.RemarkList()
      .then((res) => {
        let remOptions = frameSelectOptions(res.message, "remarks_id", "remarks");
        setRemarkOption(remOptions);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const update_status = () => {
    ServerAPI.updateIPVStatus(verify_status, remarks, user_id).then((res) => {
      toast.success(res["message"]);
      navigate(PageLinks.USERSDETAILS, { state: user_id });
    });
  };

  return (
    <div className="page-style">
      <div className="step_box_content">
        <div className="col-xl-12">
          <div className="row justify-content-center mx-5 my-5">
            <div className="sub-bg">
              <div className="col-lg-10 box-center">
                <div className=" row ">
                  <div className="subscribe-wrapper">
                    <div className="section-title section-title-3">
                      <h6 className="mb-0 px-3 py-2 wow fadeInUp2 animated" data-wow-delay="0.1s">
                        IPV Verification
                      </h6>
                      <Link to={PageLinks.USERSDETAILS} state={user_id} className="theme_btn profile_close_btn sub-btn wow fadeInUp2 animated" data-wow-delay="0.5s">
                        <i className="far fa-times-circle"></i> Close
                      </Link>
                      <hr></hr>
                      <form className="quote-form mb-10" action="#">
                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 pl-0 pt-20 float-left">
                            <div className="email-input input_field pl-0">
                              <label className="input-title">IPV Code</label>
                              <div className="partitioned_input_whole">
                                {IpvDetails?.proof_detail.ipv_verification_code.split("").map((res: any, i: any) => {
                                  return (
                                    <div className="partitioned_input_box" key={i}>
                                      <div className="instruc_img_text">
                                        <b>{res}</b>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              {/* <div className="my-3">
                                                                <label className="input-title pb-3">PAN</label>
                                                                <ImageMagnifier
                                                                    src={'/assets/images/PAN_Card.jpg'}
                                                                    // src={IpvDatas?. ? "data:image/png;base64,"+IpvDatas.digilocker_image :"'/assets/images/aadhar.png'"}
                                                                />
                                                            </div> */}

                              <div className="my-3">
                                <label className="input-title pb-3">Aadhaar</label>
                                <ImageMagnifier src={IpvDetails?.proof_detail.aadhar_image ? "data:image/png;base64," + IpvDetails?.proof_detail.aadhar_image : "'/assets/images/aadhar.png'"} />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 pl-0 pt-20 float-left">
                            <div className="email-input input_field pl-0 mt-3 mb-3">
                              <div className="my-3 ">
                                <video height={250} autoPlay playsInline controls={true} controlsList="nodownload noplaybackrate" src={IpvDetails?.proof_detail.ipv_verification_video}>
                                  <source type="video/mp4 " />
                                </video>
                              </div>
                            </div>
                          </div>
                          <div className="my-4 col-lg-12 col-md-12 col-sm-12 card-padding">
                            <div className="card ">
                              <div className="card-header">
                                <h5 className="m-0 header"> Details of you given </h5>
                              </div>
                              <div className="card-body ipv-card-body ">
                                <div className="row">
                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    <p className="detail-title ipv-card-text">User Name</p>
                                    {IpvDetails?.proof_detail.user_name === "" || IpvDetails?.proof_detail.user_name === null ? <h6> N/A </h6> : <h6>{IpvDetails?.proof_detail.user_name}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    <p className="detail-title ipv-card-text">Pan Name</p>
                                    {!IpvDetails?.proof_detail.pan_name && <h6>N/A</h6>}
                                    {IpvDetails?.proof_detail.pan_name === "" || IpvDetails?.proof_detail.pan_name === null ? <h6> N/A </h6> : <h6>{IpvDetails?.proof_detail.pan_name}</h6>}
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    <p className="detail-title ipv-card-text">Aadhaar Name</p>
                                    {IpvDetails?.proof_detail?.aadhar_name === "" || IpvDetails?.proof_detail?.aadhar_name === null ? <h6> N/A </h6> : <h6>{IpvDetails?.proof_detail?.aadhar_name}</h6>}
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    {IpvDetails?.proof_match?.name_match === 1 ? (
                                      <a className="matched mx-2">
                                        <b>MATCHED</b>
                                      </a>
                                    ) : (
                                      <a className="not-match mx-2">
                                        <b>NOT MATCHED</b>
                                      </a>
                                    )}
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    <p className="detail-title ipv-card-text">User Date Of Birth</p>
                                    {IpvDetails?.proof_detail?.user_dob === "" || IpvDetails?.proof_detail?.user_dob === null ? (
                                      <h6> N/A </h6>
                                    ) : (
                                      <h6>{moment(IpvDetails?.proof_detail?.user_dob).format("DD-MM-YYYY")}</h6>
                                    )}
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    <p className="detail-title ipv-card-text">Pan Date Of Birth</p>
                                    {!IpvDetails?.proof_detail.pan_dob && <h6>N/A</h6>}
                                    {IpvDetails?.proof_detail.pan_dob === "" || IpvDetails?.proof_detail.pan_dob === null ? <h6> N/A </h6> : <h6>{IpvDetails?.proof_detail.pan_dob}</h6>}
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    <p className="detail-title ipv-card-text">Aadhaar Date Of Birth</p>
                                    {IpvDetails?.proof_detail.aadhar_dob === "" || IpvDetails?.proof_detail.aadhar_dob === null ? (
                                      <h6> N/A </h6>
                                    ) : (
                                      <h6>{moment(IpvDetails?.proof_detail.aadhar_dob).format("DD-MM-YYYY")}</h6>
                                    )}
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    {IpvDetails?.proof_match.dob_match === 1 ? (
                                      <a className="matched mx-2">
                                        <b>MATCHED</b>
                                      </a>
                                    ) : (
                                      <a className="not-match mx-2">
                                        <b>NOT MATCHED</b>
                                      </a>
                                    )}
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    <p className="detail-title ipv-card-text">User Address</p>
                                    {IpvDetails?.proof_detail.user_address === "" || IpvDetails?.proof_detail.user_address === null ? <h6> N/A </h6> : <h6>{IpvDetails?.proof_detail.user_address}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    <p className="detail-title ipv-card-text">Pan Address</p>
                                    {IpvDetails?.proof_detail?.pan_address === "" || IpvDetails?.proof_detail?.pan_address === null ? <h6> N/A </h6> : <h6>{IpvDetails?.proof_detail?.pan_address}</h6>}
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    <p className="detail-title ipv-card-text">Aadhaar Address</p>
                                    {IpvDetails?.proof_detail.aadhar_address === "" || IpvDetails?.proof_detail.aadhar_address === null ? (
                                      <h6> N/A </h6>
                                    ) : (
                                      <h6>{IpvDetails?.proof_detail.aadhar_address}</h6>
                                    )}
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                    {IpvDetails?.proof_match?.address_match === 1 ? (
                                      <a className="matched mx-2">
                                        <b>MATCHED</b>
                                      </a>
                                    ) : (
                                      <a className="not-match mx-2">
                                        <b>NOT MATCHED</b>
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 pl-0 pt-20 float-left">
                            <div className="col-lg-4  email-input input_field pl-0">
                              <label className="input-title pb-3">Verification Status</label>
                              <select className="select-style" onChange={(e) => setVerifyStatus(parseInt(e.target.value))} name="verification_status">
                                <option value={1} selected={IpvDetails?.proof_detail.ipv_verification_verified == 1 ? true : false}>
                                  Verified
                                </option>
                                <option value={2} selected={IpvDetails?.proof_detail.ipv_verification_verified == 0 ? true : false}>
                                  Rejected
                                </option>
                              </select>
                            </div>
                            <div className=" email-input input_field pl-0">
                              <label className="input-title pb-3">Remarks</label>
                              <select className="select-style" placeholder="Select From drop down" name="remarks" onChange={(e) => setRemarks(parseInt(e.target.value))}>
                                {remarkOption.map((option: FormDataTypes.SelectOption) => (
                                  <option value={option.value}>{option.label}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="row text-end my-3 py-5 ">
                            <div className="">
                              <Link to={PageLinks.USERSDETAILS} state={user_id} className="cancel-btn mx-3">
                                Cancel
                              </Link>
                              {/* </div>
                                                        <div className='col-lg-6'> */}
                              <a className="save-btn" onClick={() => update_status()}>
                                Save
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IpvVerification;
