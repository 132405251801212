import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { AccountStatus, Depository, PageLinks } from "../common/Constants";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import { getDisplayData, getFormattedLocalDate, getFormattedLocalTime, getIsLogin } from "../common/Utilities";
import SuspendedModal from "../modals/Suspended-modal";

var startSerialNo = 0;
const Users: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const suspended_data = location.state;
  // const [suspended_data, setSuspended_data] = useState(location.state);
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [userList, setUserList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  // const [enable_desable, setEnableDesable] = useState(true);
  const [search_text, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [user_id, setUserId] = useState();
  const { setShowPreloader, activeColor, setActiveColor } = useAppStateAPI();
  const ref = React.createRef();
  const [currentPage, setCurrentPage] = useState(1);
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;

  useEffect(() => {
    getIsLogin().then((status) => {
      console.log(status);
      if (!status) {
        navigate(PageLinks.LOGIN);
      }
    });
  }, []);

  useEffect(() => {
    userList_data();
  }, [pageNumber, search_text, toData, fromDate, suspended_data]);

  const userList_data = () => {
    setShowPreloader(true);
    ServerAPI.userDetailData(pageNumber, search_text, toData, fromDate)
      .then((res) => {
        setUserList(res.message);
        setTotalCount(res.total_records);
        // console.log(res.datas.filter((e: any) => { return e.new_demat_account_details != undefined && e.new_demat_account_details.length > 0 }))
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleClickOpen = () => {};
  const handleClose = (e: any) => {
    setOpen(e);
    userList_data();
  };

  const openPopUp = (e: any) => {
    setOpen(true);
    setUserId(e);
  };

  const back_page = () => {
    navigate(PageLinks.SUSPENDED_USER_LIST);
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 51.297 58.625">
                    <path id="Path_22586" data-name="Path 22586" d="M-1365.036,907.615a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-40.3a5.5,5.5,0,0,1,5.5-5.5h5.5V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h14.656V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h5.5a5.5,5.5,0,0,1,5.5,5.5Zm-5.5-34.808h-40.3v34.121a.689.689,0,0,0,.687.687h38.93a.689.689,0,0,0,.687-.687Zm-33.434,14.656a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58a1.378,1.378,0,0,1-1.374,1.374Zm4.58,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1399.386,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,898.455Z" transform="translate(1416.333 -854.486)" />
                </svg> */}
        <img src="../../assets/images/calender-icon.svg" onClick={props.onClick} ref={ref} />
      </div>
    );
  });

  return (
    <div>
      <div>
        <div id="layout-wrapper">
          <Header />
          <div className="main-content py-4">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                      <div className="page-title-rightds">
                        {suspended_data !== true ? (
                          <ol className="breadcrumb ">
                            <li className="breadcrumb-item">
                              <a style={{ color: "#90929E" }}>Trustmony</a>
                            </li>
                            <li className="breadcrumb-item active">
                              <b>All Users</b>
                            </li>
                          </ol>
                        ) : (
                          <ol className="breadcrumb ">
                            <li className="breadcrumb-item">
                              <a style={{ color: "#90929E" }}>Trustmony</a>
                            </li>
                            <li className="breadcrumb-item ">Users</li>
                            <li className="breadcrumb-item "> Suspend Users</li>
                            <li className="breadcrumb-item active">
                              <b>Add</b>
                            </li>
                          </ol>
                        )}
                      </div>
                      {suspended_data === true && (
                        <div className="cursor-pointer" onClick={() => back_page()}>
                          <p className="mb-0 active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                              <path
                                id="Path_22590"
                                data-name="Path 22590"
                                d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z"
                                transform="translate(1775.371 -858.797)"
                              />
                            </svg>
                            <span> Back </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                        <div className="row">
                          <div className="col-xl-8">
                            <div className="d-flex justify-content-start align-items-center">
                              {suspended_data !== true ? <h3 className="card-heading p-2 m-0">All User</h3> : <h3 className="card-heading p-2 m-0">Add Suspend Users</h3>}
                              <p className="text-bold p-2 m-0">Search :</p>
                              <form className="app-search card-title p-2 m-0">
                                <div className="position-relative">
                                  <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="col-xl-4 text-end">
                            <div className="d-flex justify-content-end align-items-center">
                              <form className="app-search card-title p-2 w-100">
                                <div className="position-relative">
                                  {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={true} name="dob" format="dd/MM/yyyy"
                                                                        dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY"
                                                                        onChange={setFromDate} value={fromDate}
                                                                        calendarIcon={
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 51.297 58.625">
                                                                                <path id="Path_22586" data-name="Path 22586" d="M-1365.036,907.615a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-40.3a5.5,5.5,0,0,1,5.5-5.5h5.5V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h14.656V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h5.5a5.5,5.5,0,0,1,5.5,5.5Zm-5.5-34.808h-40.3v34.121a.689.689,0,0,0,.687.687h38.93a.689.689,0,0,0,.687-.687Zm-33.434,14.656a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58a1.378,1.378,0,0,1-1.374,1.374Zm4.58,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1399.386,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,898.455Z" transform="translate(1416.333 -854.486)" />
                                                                            </svg>
                                                                        } /> */}
                                  <DatePicker
                                    selected={fromDate}
                                    onChange={(date: Date) => setFromDate(date)}
                                    placeholderText="From Date"
                                    className="date-picker-style"
                                    customInput={<CustomInput />}
                                  />
                                </div>
                              </form>
                              <h4 className="card-title p-2">TO</h4>
                              <form className="app-search card-title p-2 w-100">
                                <div className="position-relative">
                                  {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={false} name="dob" format="dd/MM/yyyy"
                                                                    dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY"
                                                                    onChange={setToData} value={toData}
                                                                    calendarIcon={
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 51.297 58.625">
                                                                            <path id="Path_22586" data-name="Path 22586" d="M-1365.036,907.615a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-40.3a5.5,5.5,0,0,1,5.5-5.5h5.5V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h14.656V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h5.5a5.5,5.5,0,0,1,5.5,5.5Zm-5.5-34.808h-40.3v34.121a.689.689,0,0,0,.687.687h38.93a.689.689,0,0,0,.687-.687Zm-33.434,14.656a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58a1.378,1.378,0,0,1-1.374,1.374Zm4.58,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1399.386,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,898.455Z" transform="translate(1416.333 -854.486)" />
                                                                        </svg>
                                                                    } /> */}
                                  <DatePicker
                                    selected={toData}
                                    onChange={(date: Date) => setToData(date)}
                                    placeholderText="To Date"
                                    className="date-picker-style"
                                    customInput={<CustomInput />}
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="table-rep-plugin">
                          <div className="table-responsive " data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table table-bordered">
                              <thead className="city-table">
                                <tr>
                                  <th> Sr.No</th>
                                  <th>Cust. ID</th>
                                  <th>Reg. Date</th>
                                  <th>Reg. Time</th>
                                  <th>Customer Name</th>
                                  <th>PAN Number</th>
                                  <th>Customer Type</th>
                                  <th>Mobile Number</th>
                                  <th>Bank Name</th>
                                  <th>DP Name</th>
                                  <th>Status</th>
                                  <th colSpan={3} data-priority="3" className="">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              {userList.length > 0 ? (
                                <>
                                  {userList.map((res: any, i: any) => (
                                    <tbody key={i}>
                                      <tr>
                                        <td className={`${res.account_status === 4 ? "text-red" : " "}`}>{startSerialNo + i + 1}</td>
                                        <td className={`${res.account_status === 4 ? "text-red" : " "}`}>{getDisplayData(res.customer_id)}</td>
                                        <td className={`${res.account_status === 4 ? "text-red" : " "}`}>
                                          {res.reg_datetime === "" || res.reg_datetime === null ? <h6> N/A </h6> : moment(res.reg_datetime).format("DD-MM-YYYY")}
                                        </td>
                                        <td className={`${res.account_status === 4 ? "text-red" : " "}`}>
                                          {res.reg_datetime === "" || res.reg_datetime === null ? <h6> N/A </h6> : moment(res.reg_datetime).format("hh:mm A")}
                                        </td>
                                        <td className={`${res.account_status === 4 ? "text-red" : " "}`}>{getDisplayData(res.customer_name)}</td>
                                        <td className={`${res.account_status === 4 ? "text-red" : " "}`}>{getDisplayData(res.pan_number)}</td>
                                        {res.customer_type === null && <td>N/A</td>}
                                        {res.customer_type === 0 && <td>N/A</td>}
                                        {res.customer_type === 1 && <td className={`${res.account_status === 4 ? "text-red" : " "}`}> Individual </td>}
                                        {res.customer_type === 2 && <td className={`${res.account_status === 4 ? "text-red" : " "}`}>Hindu Undivided Family (HUF)</td>}
                                        {res.customer_type === 3 && <td className={`${res.account_status === 4 ? "text-red" : " "}`}>Corporate</td>}
                                        {res.customer_type === 4 && <td className={`${res.account_status === 4 ? "text-red" : " "}`}>Non-Resident Indians (NRIs)</td>}
                                        {/* <td>{res.customer_type != null && res.customer_type !== "null" ? <>{res.customer_type}</> : "N/A"}</td> */}

                                        <td className={`${res.account_status === 4 ? "text-red" : " "}`}>{getDisplayData(res.mobile_number)}</td>
                                        <td className={`${res.account_status === 4 ? "text-red" : " "}`}>{getDisplayData(res.bank_name)}</td>
                                        <td className={`${res.account_status === 4 ? "text-red" : " "}`}>{getDisplayData(res.depository_name)}</td>
                                        {res.account_status === 0 && <td>N/A</td>}
                                        {res.account_status === 1 && <td className="text-blue">Registered</td>}
                                        {res.account_status === 2 && <td className="text-green">Active</td>}
                                        {res.account_status === 4 && <td className="text-red">Closed</td>}
                                        {res.account_status === 3 && (
                                          <td>
                                            <div className="m-0 text-brown">Suspended</div>
                                            <div className="text-small">
                                              Start{" "}
                                              <span className="text-brown">
                                                {res.suspended_start_date !== "" || res.suspended_start_date !== null ? (
                                                  <>{moment(res.suspended_start_date).format("DD-MM-YYYY")}</>
                                                ) : (
                                                  "N/A"
                                                )}
                                              </span>
                                              , End{" "}
                                              <span className="text-brown">
                                                {res.suspended_end_date !== "" || res.suspended_end_date !== null ? (
                                                  <>{moment(res.suspended_end_date).format("DD-MM-YYYY")}</>
                                                ) : (
                                                  "N/A"
                                                )}
                                              </span>
                                            </div>
                                          </td>
                                        )}

                                        <td className="">
                                          <i className="fas fa-eye table-icons px-2" onClick={() => navigate(PageLinks.USERSDETAILS, { state: res.user_id })}></i>
                                          {/* {suspended_data === true && <i className="fa fa-ban text-red cursor-pointer" aria-hidden="true" onClick={() => setOpen(true)} ></i>} */}
                                          {suspended_data === true && res.user_status_key !== AccountStatus.Suspended && res.user_status_key !== AccountStatus.Closed ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              className="pb-2 cursor-pointer"
                                              height="22"
                                              viewBox="0 0 73.276 58.625"
                                              onClick={(e) => openPopUp(res.user_id)}
                                            >
                                              <path
                                                id="Path_22599"
                                                data-name="Path 22599"
                                                d="M-1918.391,904.684v2.931a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-2.931a15.394,15.394,0,0,1,15.39-15.389c3.3,0,4.866,1.832,10.259,1.832s6.962-1.832,10.26-1.832A15.392,15.392,0,0,1-1918.391,904.684Zm-5.5,0a9.909,9.909,0,0,0-9.892-9.893c-1.684,0-4.34,1.832-10.26,1.832-5.874,0-8.588-1.832-10.259-1.832a9.91,9.91,0,0,0-9.893,9.893v2.931h40.3Zm-36.64-33.709a16.493,16.493,0,0,1,16.488-16.489,16.493,16.493,0,0,1,16.488,16.489,16.493,16.493,0,0,1-16.488,16.488A16.493,16.493,0,0,1-1960.527,870.975Zm5.5,0a11.01,11.01,0,0,0,10.992,10.992,11.009,11.009,0,0,0,10.992-10.992,11.009,11.009,0,0,0-10.992-10.993A11.01,11.01,0,0,0-1955.031,870.975Zm58.087,15.732a1.839,1.839,0,0,1,0,2.588l-1.294,1.294a1.838,1.838,0,0,1-2.588,0l-4.74-4.74-4.741,4.74a1.838,1.838,0,0,1-2.588,0l-1.293-1.294a1.839,1.839,0,0,1,0-2.588l4.74-4.74-4.74-4.74a1.839,1.839,0,0,1,0-2.588l1.293-1.294a1.839,1.839,0,0,1,2.588,0l4.741,4.74,4.74-4.74a1.839,1.839,0,0,1,2.588,0l1.294,1.294a1.839,1.839,0,0,1,0,2.588l-4.741,4.74Z"
                                                transform="translate(1969.688 -854.486)"
                                                fill="red"
                                              />
                                            </svg>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </>
                              ) : (
                                <tbody>
                                  <tr className="py-4">
                                    <td className="text-center py-5" colSpan={11}>
                                      No Data Found.
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            {totalCount > 10 ? (
                              <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                Showing {userList.length} of {totalCount} entries
                              </div>
                            ) : (
                              <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                Showing {totalCount == 0 ? 0 : totalCount} entries
                              </div>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                              <Pagination
                                count={Math.ceil(totalCount / 10)}
                                onChange={(event: any, value: any) => {
                                  setPageNumber(Number(value));
                                  setCurrentPage(Number(value));
                                }}
                                variant="outlined"
                                color="primary"
                                shape="rounded"
                                className="pagination-style"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SuspendedModal handleOpen={() => handleClickOpen} userId={user_id} openModal={open} handleClose={(e: any) => handleClose(e)} />

        <Footer />
      </div>
    </div>
  );
};

export default Users;
